import React, { FC } from 'react';

import classes from './Hero.module.scss';

interface HeroProps {
  page: HeroType;
}

export enum HeroType {
  HOME = 'Home',
  SERVICES = 'Services',
  ABOUT = 'About',
  CONTACT = 'Contact',
  ABOUT_SUBSECTION_1 = 'AboutSubsection1',
  SERVICES_SUBSECTION_1 = 'ServicesSubsection1',
  SERVICES_SUBSECTION_2 = 'ServicesSubsection2',
  PRIVACY = 'Privacy',
  FOUR_OF_FOUR = '404',
  GREY_BACKGROUND = 'GreyBackground',
  CAREERS = 'Careers',
}

const Hero: FC<HeroProps> = ({ children, page }) => {
  return (
    <>
      <div className={`${classes[`Hero${page}`]} ${classes.HeroImage}`} />
      <div>{children}</div>
    </>
  );
};

export default Hero;
