import React, { FC } from 'react';
import { Button as BootstrapButton } from 'react-bootstrap';
import { ButtonType } from 'react-bootstrap/esm/Button';
import classes from './Button.module.scss';

interface ButtonProps {
  onClick?: () => void;
  type?: ButtonType;
  disabled?: boolean;
}

const Button: FC<ButtonProps> = ({
  type = 'button',
  onClick,
  children,
  disabled,
}) => {
  return (
    <BootstrapButton
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={classes.Button}
    >
      {children}
    </BootstrapButton>
  );
};

export default Button;
