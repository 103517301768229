import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import FontNunitoBold from '../../fonts/NunitoSans-Bold.woff2';
import FontNunitoSemiBold from '../../fonts/NunitoSans-SemiBold.woff2';
import FontNunitoRegular from '../../fonts/NunitoSans-Regular.woff2';
import FontNanumRegular from '../../fonts/NanumMyeongjo-Regular.woff2';
import creaImage from '../../images/Crea_f.jpg';
import { SeoCaption } from '../../constants';

interface SeoProps {
  title: string;
}

const SEO: FC<SeoProps> = ({ title }) => {
  return (
    <Helmet
      titleTemplate={SeoCaption.TITLE_TEMPLATE}
      defaultTitle={SeoCaption.DEFAULT_TITLE}
      title={title}
      htmlAttributes={{ lang: 'en' }}
      defer={false}
    >
      <html lang="en" />
      <meta name="description" content={SeoCaption.META_DESCRIPTION} />
      <meta property="og:title" content={SeoCaption.META_TITLE} />
      <meta property="og:description" content={SeoCaption.META_DESCRIPTION} />
      <meta property="og:image" content={creaImage} />
      <link
        rel="preload"
        as="font"
        href={FontNunitoRegular}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={FontNunitoSemiBold}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={FontNunitoBold}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={FontNanumRegular}
        type="font/woff2"
        crossOrigin="anonymous"
      />
    </Helmet>
  );
};

export default SEO;
