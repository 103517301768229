import React, { FC } from 'react';
import { Link, navigate } from 'gatsby';

import classes from './Footer.module.scss';

import CreaLogo from '../../images/crea-logo.svg';

import {
  Footer as FooterConst,
  FooterCaption,
  OtherLinks,
  Socials,
} from '../../constants';

type Color = 'Primary' | 'Secondary';

interface FooterProps {
  color?: Color;
}

const Footer: FC<FooterProps> = ({ color = 'Primary' }) => {
  return (
    <div className={`${classes[color]} ${classes.Footer}`}>
      <div className={`${classes.Container} ${classes.ContainerBorder}`}>
        <div className={classes.Row} onClick={() => navigate('/')}>
          <img src={CreaLogo} alt="crea" />
        </div>
        <div className={classes.Row}>
          <Link to={OtherLinks.NOTICE}>{FooterCaption.NOTICE}</Link>
          <Link to={OtherLinks.PRIVACY}>{FooterCaption.PRIVACY}</Link>
          <div className={classes.Socials}>
            <a href={Socials.LINKED_IN} target={'_blank'} rel="noreferrer">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                className={classes.SocialIcon}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.02911 20.1814H3.29732V8.71027H7.02911V20.1814ZM5.16121 7.1455C3.9679 7.1455 3 6.20203 3 5.06297C3 4.51584 3.2277 3.99111 3.633 3.60423C4.03831 3.21735 4.58802 3 5.16121 3C5.73439 3 6.2841 3.21735 6.68941 3.60423C7.09471 3.99111 7.32241 4.51584 7.32241 5.06297C7.32241 6.20203 6.35411 7.1455 5.16121 7.1455ZM20.996 20.1814H17.2722V14.5973C17.2722 13.2665 17.2441 11.5598 15.332 11.5598C13.3918 11.5598 13.0945 13.0057 13.0945 14.5015V20.1814H9.3667V8.71027H12.9458V10.275H12.998C13.4963 9.37376 14.7133 8.42263 16.5289 8.42263C20.3057 8.42263 21 10.7966 21 13.8802V20.1814H20.996Z"
                  fill="#B1C8CF"
                />
              </svg>
            </a>
            <a href={Socials.TWITTER} target={'_blank'} rel="noreferrer">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={classes.SocialIcon}
              >
                <path
                  d="M19.1498 8.69753C19.1612 8.8598 19.1612 9.0221 19.1612 9.18437C19.1612 14.1337 15.4493 19.8366 8.66499 19.8366C6.57488 19.8366 4.63326 19.2222 3 18.1559C3.29697 18.1906 3.58247 18.2022 3.89086 18.2022C5.61546 18.2022 7.20305 17.6111 8.47083 16.6027C6.84899 16.5679 5.48985 15.4899 5.02156 14.0062C5.25001 14.041 5.47842 14.0642 5.71829 14.0642C6.0495 14.0642 6.38074 14.0178 6.6891 13.9367C4.99875 13.5889 3.73094 12.0821 3.73094 10.2623V10.216C4.22204 10.4942 4.79315 10.668 5.39844 10.6912C4.40478 10.0189 3.75379 8.8714 3.75379 7.57319C3.75379 6.87774 3.9365 6.24023 4.25631 5.68385C6.07232 7.9557 8.80203 9.43933 11.8629 9.60163C11.8058 9.32345 11.7716 9.0337 11.7716 8.74392C11.7716 6.68068 13.4162 5 15.4607 5C16.5228 5 17.4822 5.45205 18.1561 6.18229C18.9898 6.02002 19.7893 5.70705 20.4975 5.27819C20.2233 6.14754 19.6409 6.87777 18.8756 7.34138C19.618 7.26029 20.3376 7.0516 21 6.76185C20.4975 7.50365 19.8693 8.16431 19.1498 8.69753Z"
                  fill="#B1C8CF"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <div className={classes.Container}>
        <div className={classes.Row}>
          <p>{FooterConst.ADDRESS}</p>
          <p>{FooterConst.PHONE_NUMBER}</p>
        </div>
        <div className={classes.Row}>
          <p>{FooterConst.RIGHTS}</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
