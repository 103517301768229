import { SelectValues } from './components/ContactForm/ContactForm';
import { ValuesItemInterface } from './components/ValuesItem/ValuesItem';

export const API_URL = '/api';

export const WP_URL = process.env.GATSBY_WP_URL || 'https://crea.si/wp/';

export enum SeoCaption {
  DEFAULT_TITLE = 'CREA BPM',
  TITLE_TEMPLATE = '%s - CREA BPM',
  META_TITLE = 'CREA - We dare to create',
  META_DESCRIPTION = 'At CREA we deliver digitalised processes for your organisation in real time, using BPM low coding systems.',
  HOME = 'Home',
  SERVICES = 'Services',
  ABOUT = 'About',
  CONTACT = 'Contact',
  FOUR_OF_FOUR = '404',
  CAREERS = 'Careers',
}

export enum Socials {
  TWITTER = 'https://twitter.com/crea_official',
  LINKED_IN = 'https://www.linkedin.com/company/crea-d-o-o-/about/',
  BIZAGI = 'https://www.bizagi.com/en',
  ULTIMUS = 'https://www.ultimus.com/',
  APPIAN = 'https://www.appian.com/',
  TELEKOM = 'https://www.telekom.si/',
  SKB = 'https://www.skb.si/',
  GENERALI = 'https://www.generali.si/',
}

export enum NavbarCaption {
  HOME = 'Home',
  SERVICES = 'Services',
  ABOUT = 'About',
  CONTACT = 'Contact',
  CAREERS = 'Careers',
}

export enum OtherLinks {
  PRIVACY = '/privacy',
  NOTICE = '/notice',
  CONTACT_US = '/contact',
  ABOUT = '/about',
  SERVICES = '/services',
  CAREERS = '/careers',
}

export enum IndexPageCaption {
  HEADER_TITLE = 'Your #1 business partner for business applications based on BPM technologies',
  HEADER_SUBTITLE = 'We are a group of diverse and talented people, at your service to create your best future with the latest enterprise-grade technological features.',
  HEADER_DESCRIPTION = 'Learn More About Us',
  FIRST_SECTION_TITLE = 'Our services will help turn your company dreams into reality',
  FIRST_SECTION_SUBTITLE_1 = 'CREA helps partners quickly adapt to new business situations to improve their competitiveness through the implementation of business innovation.',
  FIRST_SECTION_SUBTITLE_2 = 'Learn how our services can benefit your business today.',
  FIRST_SECTION_DESCRIPTION = 'Our Services',
  SECOND_SECTION_TITLE = 'Join our next event and learn first hand how BPM can transform your business',
  SECOND_SECTION_SUBTITLE = 'Sign up and be the first to know about our upcoming events.',
  SECOND_SECTION_DESCRIPTION = 'Sign Me Up',
  SECOND_SECTION_INPUT_PLACEHOLDER = 'enter your e-mail address',
  SUBSCRIPTION_SUCCESS = 'Successfully subscribed!',
  EMAIL_ERROR = "Please use your organization's email to continue.",
}

export enum AboutPageCaption {
  HEADER_TITLE = 'At CREA we deliver digitalised processes for your organisation in real time, using BPM low coding systems.',
  FIRST_SECTION_LEFT_COLUMN = 'We dare to create a world that can trust in the Data upon which it relies.',
  SECOND_SECTION_TITLE = 'Led by Our Values',
  THIRD_SECTION_LEFT_COLUMN_TITLE = 'With 20 years ',
  THIRD_SECTION_LEFT_COLUMN_DESCRIPTION = 'of experience and proven results delivering solutions based on BPM systems, for organisations in various industries.',
  THIRD_SECTION_RIGHT_COLUMN_TITLE = 'Join our trusted partners',
  THIRD_SECTION_RIGHT_COLUMN_DESCRIPTION = 'Learn how we can transform your business today.',
  THIRD_SECTION_RIGHT_COLUMN_BUTTON = 'Contact Us Now',
  LAST_SECTION_LEFT_COLUMN = 'We dare to create for every business’ success.',
  LAST_SECTION_RIGHT_COLUMN_P1 = 'CREA is a consulting and software development firm based in Ljubljana, Slovenia, focusing on the delivery of business process management, business web applications and secure e-business IT solutions.',
  LAST_SECTION_RIGHT_COLUMN_P2 = 'Through the synergy of extensive experience and knowledge of highly qualified leading experts from various fields, CREA enables organisations to achieve greater efficiency in digitalisation of their business processes.',
  LAST_SECTION_RIGHT_COLUMN_P3 = 'We are always on the lookout for talented developers to join our BPM development team.',
  LAST_SECTION_LINK = 'Join our Team',
  JOIN_US_LINK = 'I want to join your team',
}

export enum CareersPageCaption {
  HEADER_TITLE = 'Work at CREA',
  FIRST_SECTION_LEFT_COLUMN = 'A constantly growing team.',
  FIRST_SECTION_RIGHT_COLUMN_P1 = 'The future is bright for us. Proved pioneers over 20 years, we are working on the business solutions of the present and of the future. Continuously growing, business opportunities are coming to us and, if you are a winner like we are, we invite you to do the same.',
  FIRST_SECTION_RIGHT_COLUMN_P2 = 'We offer work on international projects with large scale impact, access to expert knowledge worldwide, use of new and innovative technology.',
  SECOND_SECTION_TITLE = 'Open positions',
  SECOND_SECTION_DESCRIPTION = 'We offer a productive and stimulating work environment in our offices in Ljubljana.\n',
  APPLY = 'Apply now',
  JOB_LINK = 'https://creahrm.bamboohr.com/careers',
  JOB_TITLE = 'All open positions',
}

export enum CareerDetailCaption {
  HEADER_TITLE = 'Grow With Us',
  FIRST_SECTION_LEFT_COLUMN = 'Position',
  OTHER_POSITIONS = 'View Other Positions',
  SECOND_SECTION_TITLE = 'Apply for this position',
  SECOND_SECTION_DESCRIPTION_P1 = 'Please fill out this form and provide more information about yourself.',
  SECOND_SECTION_DESCRIPTION_P2 = 'Our HR team will be in contact with you soon.',
}

export enum ServicesPageCaption {
  HEADER_TITLE = 'As a true business partner, your success is our success.',
  HEADER_SUBTITLE = 'CREA helps partners quickly adapt to new business situations to improve their competitiveness through the implementation of business innovation.',
  HEADER_DESCRIPTION = 'Contact Us',
  FIRST_SECTION_LEFT_COLUMN = 'We value our partnerships for change.',
  FIRST_SECTION_RIGHT_COLUMN = 'CREA brings you the unmissable, state-of-the-art platforms to transform your business with smarter applications in the fastest and easiest way possible. We do this using intelligent business process management systems (iBPMS), dynamic case management (DCM), digital process automation (DPA), and low code development.',
  SERVICE_LIST_ITEM_1_TITLE = 'You decide',
  SERVICE_LIST_ITEM_1_DESCRIPTION = 'When you decide to create your future with us, we will review your business goals and requirements to make sure they perfectly align with your optimised processes, providing you with the best strategic approach possible.',
  SERVICE_LIST_ITEM_2_TITLE = 'We Consult',
  SERVICE_LIST_ITEM_2_DESCRIPTION = 'We will help you to establish the correct strategy for your digitalisation journey. We understand the importance of choosing the right processes for organisations to provide them the biggest business value.',
  SERVICE_LIST_ITEM_2_LINK = 'Ask for Business Analyst Team',
  SERVICE_LIST_ITEM_3_TITLE = 'We Optimise',
  SERVICE_LIST_ITEM_3_DESCRIPTION = 'We aim to optimise your E2E processes before their digitalisation. Including your customers, partners and employees to overcome any internal silos, thus improving operational efficiency and lowering business risks.',
  SERVICE_LIST_ITEM_3_LINK = 'Ask for Process Analyst Team',
  SERVICE_LIST_ITEM_4_TITLE = 'We Envision',
  SERVICE_LIST_ITEM_4_DESCRIPTION = 'We design state of the art BPM solutions, providing our clients with an easy to use solution which will impress their customers, partners and employees alike.',
  SERVICE_LIST_ITEM_4_LINK = 'Ask for Creative Team',
  SERVICE_LIST_ITEM_5_TITLE = 'We Execute',
  SERVICE_LIST_ITEM_5_DESCRIPTION = 'We develop and continuously improve our BPM solutions in real time, using proven project methodology.  We guarantee to establish an effective framework for the delivery and changing needs of your solution.',
  SERVICE_LIST_ITEM_5_LINK = 'Ask for Dev Team',
  SERVICE_LIST_ITEM_6_TITLE = 'We Deliver',
  SERVICE_LIST_ITEM_6_DESCRIPTION = 'We develop and continuously improve our BPM solutions in real time, using proven project methodology.  We guarantee to establish an effective framework for the delivery and changing needs of your solution.',
  SERVICE_LIST_ITEM_6_LINK = 'Ask for Implementation Team',
  THIRD_SECTION_TITLE = 'The right change needs the right partners.',
  THIRD_SECTION_SUBTITLE = 'We are offering you the world leading BPM low coding platforms for your process digitalisation endeavours.',
  THIRD_SECTION_LOGO_1_LABEL = 'Low-Code Enterprise Solutions for Digital Process Automation',
  THIRD_SECTION_LOGO_2_LABEL = 'Empowering people to drive digital transformation.',
  THIRD_SECTION_LOGO_3_LABEL = 'Appian is the only recognized leader in both low code and automation.',
  FOURTH_SECTION_TITLE = 'Our transversal approach is applied on all levels and processes within your organisation.',
  SERVICE_CARD_ITEM_1_TITLE = 'Assess',
  SERVICE_CARD_ITEM_1_DESCRIPTION = 'Process Selection in order to guarantee success and user satisfaction.',
  SERVICE_CARD_ITEM_2_TITLE = 'Design',
  SERVICE_CARD_ITEM_2_DESCRIPTION = 'Define and design the solution in Bizagi Terms.',
  SERVICE_CARD_ITEM_3_TITLE = 'Build',
  SERVICE_CARD_ITEM_3_DESCRIPTION = 'Build the process to meet your unique requirements.',
  SERVICE_CARD_ITEM_4_TITLE = 'Certify',
  SERVICE_CARD_ITEM_4_DESCRIPTION = 'Ensure that the process meets business needs.',
  SERVICE_CARD_ITEM_5_TITLE = 'Implement',
  SERVICE_CARD_ITEM_5_DESCRIPTION = 'Ensure the process is production environment ready.',
  FOURTH_SECTION_FOOTER_TITLE = 'Our experts will immerse themselves with your dedicated project team to envision the perfect and simplest solutions. Finally, our developers dedicate themselves to execute the programmation in the quickest and most efficient way.',
  FOURTH_SECTION_FOOTER_SUBTITLE = 'Transform Your Business Today',
}

export enum FooterCaption {
  NOTICE = 'Cookie Notice',
  PRIVACY = 'Privacy Policy',
}

export enum ContactUsCaption {
  HEADER_TITLE = 'Contact Us',
  DESCRIPTION_1 = 'Thank you for your interest in our services. Please provide more details regarding your needs.',
  DESCRIPTION_2 = 'Our team will be happy to guide you through the process of transforming your business.',
  LABEL_NAME_1 = 'SUBJECT',
  LABEL_NAME_2 = 'FULL NAME',
  LABEL_NAME_3 = 'YOUR E-MAIL ADDRESS',
  LABEL_NAME_4 = 'YOUR MESSAGE',
  LABEL_PHONE_NUMBER = 'PHONE NUMBER',
  LABEL_COUNTRY = 'COUNTRY OF RESIDENCE',
  LABEL_AVAILABILITY = 'WHEN ARE YOU WILLING TO START?',
  LABEL_COMPANY_ORGANIZATION = 'COMPANY/ORGANIZATION',
  CHECK_BOX_1 = 'I have read and agree to CREA',
  CHECK_BOX_2 = 'Privacy Policy',
  UPLOAD_CV = 'Upload your CV',
  UPLOAD_ADDITIONAL = 'Upload Additional Files',
  SEND = 'Send',
  FILE_SELECTED_TEXT = 'File selected:',
  NO_FILE_SELECTED_TEXT = 'No file selected',
  MODAL_BODY = 'An error occurred while trying to submit the form.',
  MODAL_BUTTON = 'Try Again',
  FILE_SIZE_LIMIT = 'The file exceeds the size limit (8MB).',
  MODAL_SUCCESS = 'Success!',
  SUBMIT = 'Submit',
  EMAIL_ERROR = "Please use your organization's email to continue.",
}

export enum Caption404 {
  HEADER_TITLE = 'We’re sorry',
  DESCRIPTION_1 = 'It seems that the page you were looking for cannot be found. This can mean that you are searching for a page that doesn’t exist.',
  DESCRIPTION_2 = 'Please return to our ',
  DESCRIPTION_3 = ' or ',
  DESCRIPTION_4 = ' if you can’t find what you are looking for.',
  LINK_1 = 'home page',
  LINK_2 = 'contact us',
}

export const CV_INPUT_SHOW = 'I want to join your team';

export const ContactUsSubjects: SelectValues[] = [
  {
    link: '',
    dropdown: 'We need CREA for a total BPM change',
  },
  {
    link: ServicesPageCaption.SERVICE_LIST_ITEM_2_LINK,
    dropdown: 'We need a Business Analyst Team',
  },
  {
    link: ServicesPageCaption.SERVICE_LIST_ITEM_3_LINK,
    dropdown: 'We need a Process Analyst Team',
  },
  {
    link: ServicesPageCaption.SERVICE_LIST_ITEM_4_LINK,
    dropdown: 'We need a Creative Team',
  },
  {
    link: ServicesPageCaption.SERVICE_LIST_ITEM_5_LINK,
    dropdown: 'We need a Dev Team',
  },
  {
    link: ServicesPageCaption.SERVICE_LIST_ITEM_6_LINK,
    dropdown: 'We need an Implementation Team',
  },
  {
    link: AboutPageCaption.JOIN_US_LINK,
    dropdown: CV_INPUT_SHOW,
  },
];

export const ValuesItems: ValuesItemInterface[] = [
  {
    title: 'Responsibility',
    description:
      'Only with honesty and commitment are we able to deliver on our promises. Our work shows the responsibility we have towards our clients, team, and community.',
  },
  {
    title: 'Expertise',
    description:
      'Our curious work environment thrives on new knowledge and delivers the needed expertise for success and excellence, both for our team and clients.',
  },
  {
    title: 'Relationships',
    description:
      'Our success is driven by strong and transparent relationships, both internally and externally. We build trust upon honesty and mutual respect.',
  },
  {
    title: 'Simplicity',
    description:
      'With our knowledge and creativity, we can simplify even the most complex of tasks. The best solutions are simple solutions.',
  },
  {
    title: 'Efficiency',
    description:
      'We tackle our tasks with efficient work habits and skillful performance. We are flexible and accurate, and we are quick to adapt to any challenges that come our way.',
  },
  {
    title: 'Courage',
    description:
      'We thrive on and embrace new challenges with a determination and persistence that defines our work. We take the initiative and reward courageous behaviour.',
  },
];

export const AvailabilityOptions = ['Immediately', 'In two months', 'Later'];

export enum Footer {
  ADDRESS = 'Gospodinjska ulica 8, 1000 Ljubljana, Slovenia',
  PHONE_NUMBER = '00386 (0)1 444 06 30',
  RIGHTS = '©2022 CREA. All rights reserved.',
}

export enum CookieConsentCaption {
  ACCEPT = 'Accept all',
  DECLINE = 'Decline',
  FIRST_PART = 'By using this site, you agree to our use of cookies, which we use to analyse our traffic in accordance with our ',
  SECOND_PART = '. We may also share information about your use of our site with our analytics partners. Please read our ',
  THIRD_PART = ' for more information.',
}
